<template>
  <div>
    <status-page-password @loadPage="setPasswordAndLoad($event)"
                          :statusPagePasswordError.sync="errorStatus"
                          :slug="slug"
    />
    <div class="Status" v-if="statusPage">
      <status-page v-if="statusPage"
                   :status-page="statusPage" />

      <status-page-not-found v-if="notFound" />
    </div>
  </div>
</template>

<script>
import StatusPage from '@/components/StatusPage/StatusPage.vue'
import StatusPageNotFound from '@/components/StatusPage/StatusPageNotFound.vue'
import statusPagesApi from '@/api/statusPagesApi.js'
import StatusPagePassword from '@/components/StatusPage/Modals/StatusPagePassword'

export default {
  components: {
    StatusPagePassword,
    StatusPage,
    StatusPageNotFound
  },

  props: {
    slug: {
      required: false,
      type: String
    }
  },

  metaInfo () {
    return {
      meta: this.metaTags
    }
  },

  data () {
    return {
      statusPage: null,
      notFound: false,
      errorStatus: false,
      password: sessionStorage.getItem('password'),
      metaTags: []
    }
  },

  created () {
    this.loadStatusPage()

    setInterval(this.loadStatusPage, 1000 * 60)
  },

  methods: {
    setPasswordAndLoad (password) {
      this.password = password
      sessionStorage.setItem('password', this.password)
      this.loadStatusPage()
    },

    async loadStatusPage () {
      this.notFound = false
      try {
        if (window.location.hostname === process.env.VUE_APP_CORE_DOMAIN) {
          this.statusPage = await statusPagesApi.findBySlugOrDomain(this.slug, this.password)
        } else {
          this.statusPage = await statusPagesApi.findBySlugOrDomain(window.location.hostname, this.password)
          if (this.statusPage.google_analytics_id && !document.getElementById('ga-script')) {
            this.addGoogleAnalytics(this.statusPage.google_analytics_id)
          }
        }
        if (this.statusPage.no_index) {
          this.metaTags = [...this.metaTags, {
            name: 'robots', content: 'noindex'
          }]
        }
        this.$modal.hide('statusPagePassword')
      } catch (error) {
        if (error.response.status === 403) {
          this.errorStatus = !this.errorStatus
          this.$modal.show('statusPagePassword')
        }
        this.notFound = true
      }
    },

    addGoogleAnalytics (trackingId) {
      // Inject Google Analytics script
      const script = document.createElement('script')
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
      script.id = 'ga-script'
      document.head.appendChild(script)

      script.onload = () => {
        window.dataLayer = window.dataLayer || []

        function gtag () {
          window.dataLayer.push(arguments)
        }

        gtag('js', new Date())
        gtag('config', trackingId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Status {
    height: 100vh;
  }
</style>
