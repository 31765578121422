<template>
  <div
    class="StatusPageHeader"
    :style="headerImage && {
      background: `center, linear-gradient(${isDarkenHeaderImage ? 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)'}), url(${headerImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }"
  >
    <div class="header" :class="$mq">
      <div class="header-container d-flex align-items-center justify-content-between">
        <a :href="logoLink || false" class="d-flex">
          <div v-if="logoText" class="title truncate">{{ logoText }}</div>
          <img v-else-if="logo" class="logo" :src="logo" :style="logoStyles">
          <div v-else class="title">{{ title }}</div>
        </a>
        <div v-if="statusPage.subscribe_to_updates" class="button get-updates" ref="getupdates"><span class="optional">{{ buttonGetUpdates }}</span></div>
      </div>

      <template v-if="statusPage.live_maintenance">
        <div class="overall-status">
          <div class="statusMaintenance"><img src="@/assets/images/clock-purple2.svg" alt="Maintenance Icon" /></div><div class="status-text">{{ textOngoingMaintenance }}</div>
          <div class="d-flex align-items-center status-wrapper">
            <span class="status-text last-days-text" style="font-size: 18px;">{{ textEndingIn + ' ' + endingTime }}</span>
          </div>
        </div>
      </template>

      <template v-else>
      <template v-if="monitorsStatus === 'all-up'">
      <div class="overall-status">
        <div class="status icon-circle-all-operational">✓</div><div class="status-text">{{ textMonitorsStatusAllUp }}</div>
        <div class="d-flex align-items-center status-wrapper">
          <span class="status-text last-days-text" style="font-size: 24px;">{{ textLast }} {{ numberOfDays }} {{ textDays }}</span>
        </div>
      </div>
      </template>
      <template v-else-if="monitorsStatus === 'all-down'">
        <div class="overall-status">
          <div class="status icon-circle-all-down">✗</div><div class="status-text">{{ textMonitorsStatusAllDown }}</div>
        </div>
      </template>
      <template v-else>
        <div class="overall-status">
          <div class="status icon-circle-some-down">✗</div><div class="status-text">{{ textMonitorsStatusSomeDown }}</div>
        </div>

      </template>
      </template>
    </div>

    <div v-if="statusPage.subscribe_to_updates" class="subscribe" ref="subscribe">
      <h2 class="mb-1 mt-0">
        {{textPopupGetUpdatesTitle }}
      </h2>

      <div class="mb-3 pl-1 pr-1" v-if="statusPage.title">
        {{ textPopupGetUpdatesText }}
      </div>
      <div class="mb-3 pl-1 pr-1" v-else>
        Subscribe to receive emails every time Pulsetic publishes an update
      </div>

      <form  @submit.prevent="handleSubmit">
        <div>
          <base-textbox placeholder="your@email.com"
                        v-model="email" class="w-50 my-auto"/>
          <base-button class="mt-2 w-100 get-updates-submit-button-color get-updates-submit-text-color" type="submit" v-bind:disabled="!isDisabled || saving" :loading="saving">{{ buttonPopupGetUpdates }}</base-button>
        </div>
        <base-alert v-if="error" class="subscribe-error"
                    type="error">{{errorMessage}}</base-alert>
<!--        <vue-recaptcha ref="recaptcha"-->
<!--                       size="invisible"-->
<!--                       :sitekey="recaptchaKey"-->
<!--                       @verify="handleSubmit"-->
<!--                       @expired="onCaptchaExpired" />-->
      </form>

    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import statusPagesApi from '../../api/statusPagesApi'
// import VueRecaptcha from 'vue-recaptcha'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'

export default {
  components: {
    // VueRecaptcha
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    },
    logoText: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    },
    headerImage: {
      type: String,
      default: ''
    },
    logoLink: {
      type: String,
      default: ''
    },
    isDarkenHeaderImage: {
      type: Boolean,
      default: false
    },
    logoStyles: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      tippyInstance: null,
      email: '',
      saving: false,
      error: null,
      monitorsStatus: '',
      errorMessage: 'Something went wrong.',

      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    }
  },

  mounted () {
    this.initDropdown()
    this.getMonitorsStatus()
  },

  methods: {
    initDropdown () {
      if (this.$refs.getupdates) {
        this.tippyInstance = tippy(this.$refs.getupdates, {
          content: this.$refs.subscribe,
          interactive: true,
          theme: 'subscribe-dropdown',
          animation: 'shift-away-subtle',
          placement: 'bottom',
          arrow: true,
          inertia: true,
          trigger: 'click'
        })
      }
    },
    getMonitorsStatus () {
      let count = 0
      for (const monitor of this.statusPage.monitors) {
        if (monitor.status === 'online' || monitor.status === null) {
          count++
        }
      }
      if (count === this.statusPage.monitors.length) {
        this.monitorsStatus = 'all-up'
      } else if (count === 0) {
        this.monitorsStatus = 'all-down'
      } else {
        this.monitorsStatus = 'some-down'
      }
    },
    // runCaptcha () {
    //   this.saving = true
    //   this.$refs.recaptcha.execute()
    // },

    async handleSubmit () {
      this.error = false
      this.saving = true
      try {
        const subscribeData = { statusPageId: this.statusPage.id, email: this.email }
        await statusPagesApi.subscribeToPage(subscribeData)
        this.showSuccessMessage()
      } catch (error) {
        console.log(error.response.data.message)
        this.error = true
        this.errorMessage = error.response.data.message
      }
      this.saving = false
      this.email = ''
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'If you are not already a subscriber, an email will be sent to your address. Please confirm your email.',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    }
  },

  computed: {
    endingTime () {
      if (this.statusPage.live_maintenance) {
        return dayjs(this.statusPage.live_maintenance.end).to(dayjs(), true)
      }
      return null
    },
    title () {
      if (this.statusPage && this.statusPage.title) {
        return this.statusPage.title
      }

      return 'Status Page'
    },

    textEndingIn () {
      if (this.statusPageLanguage.text_maintenance_ending_in) {
        return this.statusPageLanguage.text_maintenance_ending_in
      }
      return 'Ending in'
    },

    buttonGetUpdates () {
      if (this.statusPageLanguage.button_get_updates) {
        return this.statusPageLanguage.button_get_updates
      }

      return 'Get Updates'
    },

    textPopupGetUpdatesTitle () {
      if (this.statusPageLanguage.text_popup_get_updates_title) {
        return this.statusPageLanguage.text_popup_get_updates_title
      }

      return 'Subscribe for updates'
    },

    textPopupGetUpdatesText () {
      if (this.statusPageLanguage.text_popup_get_updates_text) {
        return this.statusPageLanguage.text_popup_get_updates_text
      }

      return `Subscribe to receive emails every time ${this.statusPage.title} publishes an update`
    },

    buttonPopupGetUpdates () {
      if (this.statusPageLanguage.button_popup_get_updates) {
        return this.statusPageLanguage.button_popup_get_updates
      }

      return 'Submit'
    },

    textMonitorsStatusAllUp () {
      if (this.statusPageLanguage.text_monitors_status_all_up) {
        return this.statusPageLanguage.text_monitors_status_all_up
      }

      return 'All Systems Operational'
    },

    textMonitorsStatusAllDown () {
      if (this.statusPageLanguage.text_monitors_status_all_down) {
        return this.statusPageLanguage.text_monitors_status_all_down
      }

      return 'Major Systems Outage'
    },

    textMonitorsStatusSomeDown () {
      if (this.statusPageLanguage.text_monitors_status_some_down) {
        return this.statusPageLanguage.text_monitors_status_some_down
      }

      return 'Partial Systems Outage'
    },
    textOngoingMaintenance () {
      if (this.statusPageLanguage.text_ongoing_maintenance) {
        return this.statusPageLanguage.text_ongoing_maintenance
      }
      return 'Ongoing Maintenance'
    },

    isDisabled () {
      return this.email.length > 0
    },
    textLast () {
      if (this.statusPageLanguage.text_uptime_last) {
        return this.statusPageLanguage.text_uptime_last
      }

      return 'Last'
    },

    textDays () {
      if (this.statusPageLanguage.text_uptime_days) {
        return this.statusPageLanguage.text_uptime_days
      }

      return 'Days'
    },
    numberOfDays () {
      if (this.$mq === 'desktop') return 90
      if (this.$mq === 'tablet') return 60
      return 30
    }
  }
}
</script>

<style lang="scss" scoped>
  .StatusPageHeader {
    margin-bottom: 140px;
    background: #19221D;
    position: relative;
    padding: 0 40px;

    .header-container {
      width: 100%;
    }

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      max-width: 904px;
      margin: 0 auto;
      padding: 40px 0 120px;
      position: relative;

      .title {
        font-weight: 600;
        font-size: 22px;
        color: #fff;
      }

      .logo {
        max-width: 100px;
        max-height: 100px;
        margin-right: 20px;

       @media screen and (max-width: 580px) {
        max-width: 34vw;
       }
      }

      &.mobile {
        margin-bottom: 20px;
      }

      .button {
        font-weight: 600;
        font-size: 16px;
        line-height: 48px;
        height: 50px;
        padding: 0 18px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        color: #fff;
        cursor: pointer;

        transition: .25s;
        display: flex;
        align-items: center;

        &.get-updates {
          white-space: nowrap;
        }

        &:hover {
          border-color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .overall-status {
      padding: 40px 45px;
      border-radius: 12px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.01), 0px 8px 30px rgba(25, 34, 29, 0.08);
      position: absolute;
      max-width: 904px;
      bottom: -80px;
      width: 100%;
      background-color: #fff;
      align-items: center;

      @media (min-width: 500px) {
        display: flex;
        bottom: -50px;
      }

      @media (max-width: 500px) {
        padding: 30px 35px;
        bottom: -110px;
      }

      @media (max-width: 400px) {
        padding: 20px 10px;
      }
      @media (max-width: 320px) {
        bottom: -100px;
      }

      .status {
        @media (min-width: 500px) {
          @include inline-flex-center;
        }
        background-color: #219653;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        flex-shrink: 0;
        margin-right: 20px;
        text-align: center;
        margin-right: 20px;

        @media (max-width: 499px) {
          margin: 0 auto 20px;
          zoom: 1.2;
        }

        &.icon-circle-all-down {
          background-color: #da615c;
        }

        &.icon-circle-some-down {
          background-color: #e59d59;
        }
      }

      .statusMaintenance {
        @media (min-width: 500px) {
          @include inline-flex-center;
        }
        width: 30px;
        height: 30px;
        border-radius: 50px;
        font-size: 20px;
        font-weight: 500;
        flex-shrink: 0;
        margin-right: 20px;
        text-align: center;

        @media (max-width: 499px) {
          margin: 0 auto 20px;
          zoom: 1.2;
        }
      }

      .status-text {
        font-weight: 500;
        font-size: 27px;
        line-height: 33px;
        letter-spacing: -0.021em;

        @media (max-width: 499px) {
          font-size: 20px;
          text-align: center;
        }
      }

      .status-wrapper {
        width: 55%;
        justify-content: flex-end;

        @media (max-width: 499px) {
          width: 100%;
          justify-content: center;
        }
      }
    }

    .subscribe {
      color: #333333;
      .btn {
        background-color: #1aaf5d !important;
      }
    }
  }
</style>
